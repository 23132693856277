import { isDefined } from "@clipboard-health/util-ts";
import { getAuthHeader } from "@src/app/superagent";
import { NodeEnvironment, environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import request from "superagent";
import { z } from "zod";

const reviewSchema = z.object({
  rating: z.number(),
  workplaceId: z.string(),
  updatedAt: z.string(),
  workplaceUserId: z.string(),
  additionalFeedback: z.string().nullish(),
});

const workplaceSchema = z.object({
  _id: z.string(),
  name: z.string(),
  userId: z.string(),
});

export enum PreferredReason {
  RATING = "RATING",
  INTERNAL_CRITERIA = "INTERNAL_CRITERIA",
  FAVORITE = "FAVORITE",
}

const preferredWorkplaceSchema = z
  .object({
    id: z.string(),
    workplaceId: z.string(),
    workerId: z.string(),
    reason: z.enum([
      PreferredReason.INTERNAL_CRITERIA,
      PreferredReason.RATING,
      PreferredReason.FAVORITE,
    ]),
    createdAt: z.string(),
    updatedAt: z.string(),
    review: reviewSchema.nullish(),
    workplace: workplaceSchema,
  })
  .refine(
    (schema) => {
      return (
        (schema.reason === PreferredReason.RATING && isDefined(schema.review)) ||
        (schema.reason !== PreferredReason.RATING && !isDefined(schema.review))
      );
    },
    {
      message: "required",
      path: ["review"],
    }
  );
export type PreferredWorkplace = z.infer<typeof preferredWorkplaceSchema>;

const preferredWorkplacesResponseSchema = z.array(preferredWorkplaceSchema);
export type PreferredWorkplacesResponse = z.infer<typeof preferredWorkplacesResponseSchema>;

export function getWorkplacesForWorkersPath() {
  return "/priority-access/workplaces-for-worker";
}

// FIXME: Migrate this hook to appV2 and rework to use useGetQuery
// Also, remove schema parsing as it is done implictly in appV2
export async function getPreferredWorkplaces(
  reason: PreferredReason
): Promise<PreferredWorkplacesResponse> {
  try {
    const response = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}${getWorkplacesForWorkersPath()}`)
      .set(await getAuthHeader())
      .query({ reason });

    if (process.env.NODE_ENV !== NodeEnvironment.DEVELOPMENT) {
      return response.body;
    }

    return preferredWorkplacesResponseSchema.parse(response.body);
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
}
