export enum ScoreSeverity {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Dark = "dark",
}

export const getScoreSeverity = (score: number) => {
  if (score >= 70) {
    return ScoreSeverity.Success;
  }
  if (score >= 30) {
    return ScoreSeverity.Warning;
  }
  if (score >= 1) {
    return ScoreSeverity.Danger;
  }

  return ScoreSeverity.Dark;
};
