import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import {
  PreferredReason,
  PreferredWorkplacesResponse,
  getPreferredWorkplaces,
  getWorkplacesForWorkersPath,
} from "./getPreferredWorkplaces";

export function usePreferredWorkplace(
  reason: PreferredReason,
  options: UseQueryOptions<PreferredWorkplacesResponse> = {}
): UseQueryResult<PreferredWorkplacesResponse> {
  return useQuery<PreferredWorkplacesResponse>(
    [getWorkplacesForWorkersPath(), reason],
    () => getPreferredWorkplaces(reason),
    options
  );
}
