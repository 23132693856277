import styled from "@emotion/styled";
import { IonSpinner, IonText } from "@ionic/react";

import { Item } from "./RatingsPage.styles";

export const Rating = styled(IonText)`
  color: var(--ion-color-warning);
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
`;

export const RatingScore = styled(IonText)`
  font-size: 20px;
`;

export const SubText = styled(IonText)`
  font-size: 15px;
`;

export const AdditionalFeedback = styled(IonText)`
  font-size: 14px;
`;

export const Workplace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const WorkplaceItem = styled(Item)`
  border-top: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  padding: 16px 0;
`;

export const WorkplaceName = styled(IonText)`
  font-size: 16px;
  font-weight: 600;
`;

export const Count = styled(IonText)`
  font-size: 48px;
  display: flex;
  justify-content: center;
  font-weight: 600;
`;

export const ExpandIcon = styled.span`
  font-size: 28px;
  font-weight: normal;
  line-height: 50%;
`;

export const Spinner = styled(IonSpinner)`
  margin-bottom: 8px;
  width: 100%;
`;
