import { IonList, IonSkeletonText } from "@ionic/react";
import { VerticalSpacing } from "@src/designsystem/VerticalSpacing/VerticalSpacing";

import { Card, Item } from "./RatingsPage.styles";

export const CardLoader = () => {
  return (
    <Card>
      <VerticalSpacing size="md" />
      <IonList lines="none">
        <Item>
          <IonSkeletonText animated style={{ width: "50%" }} />
        </Item>
      </IonList>
      <IonList lines="none">
        <Item>
          <IonSkeletonText animated />
        </Item>
      </IonList>
      <VerticalSpacing size="md" />
    </Card>
  );
};
