import { PriorityAccessCard } from "./PriorityAccessCard";
import { RatingCard, RatingCardProps } from "./RatingsCard";
import { CardLoader } from "../CardLoader";

export function RatingCardContainer(props: { cards: RatingCardProps[]; isLoading: boolean }) {
  return (
    <>
      {props.cards.map((card) => {
        if (props.isLoading) {
          return <CardLoader key={card.testId} />;
        }

        return <RatingCard key={card.testId} {...card} />;
      })}
      <PriorityAccessCard />
    </>
  );
}
