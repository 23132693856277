import { getAuthHeader } from "@src/app/superagent";
import { NodeEnvironment, environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import request from "superagent";
import { z } from "zod";

export function getWorkplaceCountPath(workerId: string) {
  return `/preferred-workers/${workerId}/statistics`;
}

const preferredWorkplaceCountResponseSchema = z.object({
  workplaceCountByReason: z.object({
    RATING: z.number(),
    INTERNAL_CRITERIA: z.number(),
    FAVORITE: z.number(),
  }),
});

export type PreferredWorkplaceCountResponse = z.infer<typeof preferredWorkplaceCountResponseSchema>;

// FIXME: Migrate this hook to appV2 and rework to use useGetQuery
// Also, remove schema parsing as it is done implictly in appV2
export async function getPreferredWorkplaceCount(
  workerId: string
): Promise<PreferredWorkplaceCountResponse> {
  try {
    const response = await request
      .get(`${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}${getWorkplaceCountPath(workerId)}`)
      .set(await getAuthHeader());

    if (process.env.NODE_ENV !== NodeEnvironment.DEVELOPMENT) {
      return response.body;
    }

    return preferredWorkplaceCountResponseSchema.parse(response.body);
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
}
