import { formatDate } from "@clipboard-health/date-time";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonIcon, IonList, IonPage, IonText } from "@ionic/react";
import { Button } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { Banner } from "@src/appV2/lib/Alert";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { VerticalSpacing } from "@src/designsystem/VerticalSpacing/VerticalSpacing";
import { star } from "ionicons/icons";
import { sortBy, startCase } from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { Link } from "react-router-dom";

import { PreferredReason } from "./api/getPreferredWorkplaces";
import { usePreferredWorkplaceCount } from "./api/usePreferredWorkplaceCount";
import { usePreferredWorkplace } from "./api/usePreferredWorkplaces";
import { CardLoader } from "./CardLoader";
import { pluralizeFacility } from "./lang";
import { logArticleClicked, logViewFacilitiesClicked } from "./logs";
import { PriorityAccessATeamCard } from "./PriorityAccessATeamCard";
import {
  AdditionalFeedback,
  Count,
  ExpandIcon,
  Rating,
  RatingScore,
  Spinner,
  SubText,
  Workplace,
  WorkplaceItem,
  WorkplaceName,
} from "./PriorityAccessPage.styles";
import { Card, CardTitle, Item } from "./RatingsPage.styles";
import { TabRouterPath } from "../routing/constant/tabRoute";

const PRIORITY_ACCESS_ARTICLE =
  "https://support.clipboardhealth.com/hc/en-us/articles/10294524931607-What-is-Priority-Access-";

function getFavoritesTextContent(favoritesCount: number) {
  if (favoritesCount === 0) {
    return "You have not been added to any facility's Favorites list. When a facility rates you 4+ stars or likes you, you will get early access to their shifts.";
  } else {
    return `${favoritesCount} ${pluralize("facility", favoritesCount)} ${pluralize(
      "has",
      favoritesCount
    )} added you to their Favorites list. This gives you early access to their shifts.`;
  }
}

function getHighAttendanceTextContent(internalCriteriaCount: number) {
  if (internalCriteriaCount === 0) {
    return "There are 0 facilities where you qualify for Priority Access through high attendance. If you have high attendance at a facility and they have not yet rated you, you get temporary early access to their shifts.";
  } else if (internalCriteriaCount === 1) {
    return `
    There is 1 facility where you have high attendance and no rating.
    If the facility doesn’t have enough highly-rated workers, you get early access to their shifts.
    Ask the facility to rate you 4+ stars so you can keep getting Priority Access!
  `;
  } else {
    return `
    There are ${internalCriteriaCount} facilities where you have high attendance and no rating.
    If these facilities don’t have enough highly-rated workers, you get early access to their shifts.
    Ask these facilities to rate you 4+ stars so you can keep getting Priority Access!
  `;
  }
}

export function PriorityAccessPage() {
  const [isFavoritedExpanded, setIsFavoritedExpanded] = useState(false);
  const [highAttendanceExpanded, setHighAttendanceExpanded] = useState(false);

  const worker = useDefinedWorker();

  const {
    isLoading: workplaceCountLoading,
    isSuccess: workplaceCountSuccess,
    data: workplaceCountData,
    refetch: refetchPreferredWorkplaceCount,
  } = usePreferredWorkplaceCount(worker.userId, {
    meta: {
      userErrorMessage: "Something went wrong while loading your priority access facilities.",
    },
  });

  const isWorkplaceByRatingEnabled =
    isFavoritedExpanded &&
    isDefined(workplaceCountData?.workplaceCountByReason?.RATING) &&
    workplaceCountData?.workplaceCountByReason?.RATING !== 0;

  const { isLoading: workplaceByRatingLoading, data: workplaceByRatingData = [] } =
    usePreferredWorkplace(PreferredReason.RATING, {
      meta: { userErrorMessage: "Something went wrong while loading your favorite facilities." },
      enabled: isWorkplaceByRatingEnabled,
    });

  const isWorkplaceByRatingLoading = workplaceByRatingLoading && isWorkplaceByRatingEnabled;

  const isWorkplaceByFavoritesEnabled =
    isFavoritedExpanded &&
    isDefined(workplaceCountData?.workplaceCountByReason?.FAVORITE) &&
    workplaceCountData?.workplaceCountByReason?.FAVORITE !== 0;

  const { isLoading: workplaceByFavoritesLoading, data: workplaceByFavoritesData = [] } =
    usePreferredWorkplace(PreferredReason.FAVORITE, {
      meta: {
        userErrorMessage: "Something went wrong while loading your favorite facilities.",
      },
      enabled: isWorkplaceByFavoritesEnabled,
    });
  const isWorkplaceByFavoritesLoading =
    workplaceByFavoritesLoading && isWorkplaceByFavoritesEnabled;

  const { isLoading: workplaceByCriteriaLoading, data: workplaceByCriteriaData } =
    usePreferredWorkplace(PreferredReason.INTERNAL_CRITERIA, {
      meta: {
        userErrorMessage: "Something went wrong while loading your high attendance facilities.",
      },
      enabled: highAttendanceExpanded,
    });

  const toggleFavorite = () => {
    setIsFavoritedExpanded((prevState) => !prevState);
    logViewFacilitiesClicked(
      worker.userId,
      workplaceByRatingData?.length ?? 0,
      "favorited",
      isFavoritedExpanded ? "listCollapsed" : "listExpanded"
    );
  };

  const toggleHighAttendance = () => {
    setHighAttendanceExpanded((prevState) => !prevState);
    logViewFacilitiesClicked(
      worker.userId,
      workplaceByCriteriaData?.length ?? 0,
      "high attendance",
      highAttendanceExpanded ? "listCollapsed" : "listExpanded"
    );
  };

  const totalNumberOfFavorites =
    (workplaceCountData?.workplaceCountByReason?.RATING ?? 0) +
    (workplaceCountData?.workplaceCountByReason?.FAVORITE ?? 0);

  const numberOfPriorityAccesses = workplaceCountSuccess
    ? totalNumberOfFavorites + workplaceCountData.workplaceCountByReason.INTERNAL_CRITERIA
    : 0;

  const favoritesTextContent = getFavoritesTextContent(totalNumberOfFavorites);

  const highAttendanceTextContent = getHighAttendanceTextContent(
    workplaceCountData?.workplaceCountByReason.INTERNAL_CRITERIA ?? 0
  );

  const enablePAExclusionExperiment = useCbhFlag(CbhFeatureFlag.PA_EXCLUSION_EXPERIMENT, {
    defaultValue: false,
  });

  const showPAExclusionWarning = enablePAExclusionExperiment && worker.stage === "RESTRICTED";

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="My Priority Access"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.RATINGS} />}
          />
        }
        containerVariant="without-margin"
        containerMaxWidth="md"
        onRefresh={refetchPreferredWorkplaceCount}
      >
        {workplaceCountLoading ? (
          <CardLoader />
        ) : (
          workplaceCountSuccess && (
            <>
              {showPAExclusionWarning && (
                <Banner onClose={undefined} severity="warning">
                  You are not receiving notifications for Priority postings because your account is
                  Restricted.
                </Banner>
              )}
              <VerticalSpacing size="lg" />
              <IonList lines="none">
                <Item>
                  <IonText>
                    Priority Access gives you early access to a facility’s open shifts.{" "}
                    <ExternalLink
                      to={PRIORITY_ACCESS_ARTICLE}
                      onClick={() => logArticleClicked(worker.userId)}
                    >
                      Learn more.
                    </ExternalLink>
                  </IonText>
                </Item>
                <Item>
                  You have Priority Access at a total of{" "}
                  {pluralizeFacility(numberOfPriorityAccesses)}.
                </Item>
              </IonList>

              <PriorityAccessATeamCard />
              <Card>
                <IonList lines="none">
                  <Item>
                    <CardTitle>Priority Access: Favorites</CardTitle>
                  </Item>
                  <IonText color="shift-priority">
                    <Count>{totalNumberOfFavorites}</Count>
                  </IonText>
                  <Item>
                    <SubText>{favoritesTextContent}</SubText>
                  </Item>
                  <VerticalSpacing size="lg" />
                  {totalNumberOfFavorites > 0 && (
                    <Button
                      onClick={toggleFavorite}
                      variant="text"
                      fullWidth
                      sx={(theme) => ({
                        /**
                         * FIXME:
                         * Remove all these custom styles and use the standard MUI theme'd styles
                         * Ticket: https://clipboardhealth.atlassian.net/browse/CH-26022
                         */
                        color: theme.palette.text.secondary,
                        justifyContent: "space-between",
                        paddingX: 2,
                        borderTop: 1,
                        borderRadius: 0,
                        borderColor: theme.palette.divider,
                      })}
                    >
                      <SubText>
                        View Favorited-at{" "}
                        {startCase(pluralizeFacility(totalNumberOfFavorites, false))} (
                        {totalNumberOfFavorites})
                      </SubText>
                      <ExpandIcon>{isFavoritedExpanded ? "–" : "+"}</ExpandIcon>
                    </Button>
                  )}
                  {isFavoritedExpanded &&
                    (isWorkplaceByRatingLoading || isWorkplaceByFavoritesLoading ? (
                      <Spinner name="dots" />
                    ) : (
                      sortBy(
                        [...workplaceByRatingData, ...workplaceByFavoritesData],
                        "updatedAt"
                      ).map((workplaceData) => {
                        const { workplace, review, reason, updatedAt } = workplaceData;
                        return (
                          <WorkplaceItem key={workplace.userId}>
                            <Workplace>
                              <Link to={`/home/facility/${workplace.userId}`}>
                                <WorkplaceName>{workplace.name}</WorkplaceName>
                              </Link>
                              {reason === PreferredReason.RATING && isDefined(review) && (
                                <>
                                  <Rating>
                                    <RatingScore>{review.rating.toFixed(1)}</RatingScore>
                                    <IonIcon icon={star}></IonIcon>
                                  </Rating>
                                  {review.additionalFeedback && (
                                    <AdditionalFeedback>
                                      “{review.additionalFeedback}”
                                    </AdditionalFeedback>
                                  )}
                                  <Text variant="body2">{formatDate(review.updatedAt)}</Text>
                                </>
                              )}
                              {reason === PreferredReason.FAVORITE && (
                                <Text variant="body2">{formatDate(updatedAt)}</Text>
                              )}
                            </Workplace>
                          </WorkplaceItem>
                        );
                      })
                    ))}
                </IonList>
              </Card>
              <Card>
                <IonList lines="none">
                  <Item>
                    <CardTitle>Priority Access: High Attendance</CardTitle>
                  </Item>
                  <IonText color="shift-priority">
                    <Count>{workplaceCountData.workplaceCountByReason.INTERNAL_CRITERIA}</Count>
                  </IonText>
                  <Item>
                    <SubText>{highAttendanceTextContent}</SubText>
                  </Item>
                  <VerticalSpacing size="lg" />
                  {workplaceCountData.workplaceCountByReason.INTERNAL_CRITERIA > 0 && (
                    <Button
                      variant="text"
                      fullWidth
                      sx={(theme) => ({
                        /**
                         * FIXME:
                         * Remove all these custom styles and use the standard MUI theme'd styles
                         * Ticket: https://clipboardhealth.atlassian.net/browse/CH-26022
                         */
                        color: theme.palette.text.secondary,
                        justifyContent: "space-between",
                        paddingX: 2,
                        borderTop: 1,
                        borderRadius: 0,
                        borderColor: theme.palette.divider,
                      })}
                      onClick={toggleHighAttendance}
                    >
                      <SubText>
                        View High Attendance{" "}
                        {startCase(
                          pluralizeFacility(
                            workplaceCountData.workplaceCountByReason.INTERNAL_CRITERIA,
                            false
                          )
                        )}{" "}
                        ({workplaceCountData.workplaceCountByReason.INTERNAL_CRITERIA})
                      </SubText>
                      <ExpandIcon>{highAttendanceExpanded ? "–" : "+"}</ExpandIcon>
                    </Button>
                  )}
                  {highAttendanceExpanded &&
                    (workplaceByCriteriaLoading ? (
                      <Spinner name="dots" />
                    ) : (
                      workplaceByCriteriaData?.map(({ workplace }) => (
                        <WorkplaceItem key={workplace.userId}>
                          <Workplace>
                            <Link to={`/home/facility/${workplace.userId}`}>
                              <WorkplaceName>{workplace.name}</WorkplaceName>
                            </Link>
                          </Workplace>
                        </WorkplaceItem>
                      ))
                    ))}
                </IonList>
              </Card>
              <Card>
                <IonList lines="none">
                  <Item>
                    <CardTitle>Learn More</CardTitle>
                  </Item>
                  <VerticalSpacing size="md" />
                  <Button
                    variant="text"
                    fullWidth
                    sx={(theme) => ({
                      /**
                       * FIXME:
                       * Remove all these custom styles and use the standard MUI theme'd styles
                       * Ticket: https://clipboardhealth.atlassian.net/browse/CH-26022
                       */
                      color: theme.palette.text.secondary,
                      justifyContent: "space-between",
                      paddingX: 2,
                      borderTop: 1,
                      borderRadius: 0,
                      borderColor: theme.palette.divider,
                    })}
                    href={PRIORITY_ACCESS_ARTICLE}
                    target="_blank"
                    onClick={() => logArticleClicked(worker.userId)}
                  >
                    <SubText>How Priority Access Works</SubText>
                  </Button>
                </IonList>
              </Card>
            </>
          )
        )}
      </PageWithHeader>
    </IonPage>
  );
}
