import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import {
  PreferredWorkplaceCountResponse,
  getPreferredWorkplaceCount,
  getWorkplaceCountPath,
} from "./getPreferredWorkplaceCount";

export function usePreferredWorkplaceCount(
  userId: string,
  options: UseQueryOptions<PreferredWorkplaceCountResponse> = {}
): UseQueryResult<PreferredWorkplaceCountResponse> {
  return useQuery<PreferredWorkplaceCountResponse>(
    [getWorkplaceCountPath(userId)],
    () => getPreferredWorkplaceCount(userId),
    { enabled: Boolean(userId), ...options }
  );
}
